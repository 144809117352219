<template>
  <!--TODO add all the error type-->
  <ErrorPages v-if="errorCode" :errorCode="404" />
  <div
    class="flex flex-col lg:flex-row items-start gap-x-14 gap-y-4 px-6 py-6 h-full"
    v-else
  >
    <!-- LEFT PART -->
    <!-- Skeleton -->
    <div
      class="flex flex-col items-start gap-8 lg:min-w-[30%] lg:max-w-[30%] min-w-full max-w-full"
      v-if="isCorporateDetailsLoading"
    >
      <Skeleton class="w-40 h-8 mb-0"></Skeleton>
      <Skeleton class="w-12 h-6 mb-0"></Skeleton>
      <div class="flex flex-col gap-4 items-start w-full">
        <Skeleton class="w-20 h-5 mb-0"></Skeleton>
        <Skeleton class="w-full h-5 mb-0"></Skeleton>
      </div>
    </div>
    <!-- Real content -->

    <div
      class="flex flex-col items-start gap-6 lg:min-w-[30%] lg:max-w-[30%] min-w-full max-w-full"
      data-cy="left-part"
      v-else
    >
      <!-- Corporate title -->
      <div class="flex justify-between w-full">
        <div
          v-if="challengeDetails?.Challenge_Visibility__c == 'Public'"
          class="flex items-center gap-2"
          data-cy="public-name"
        >
          <base-logo :companyId="corporateDetails.Id" classes="w-8 h-8" />
          <span class="text-black font-bold text-2xl text-left">{{
            corporateDetails.Name
          }}</span>
          <base-sponsorship-badge :id="corporateDetails.Id" />
        </div>
        <div v-else class="flex flex-col gap-2">
          <div class="flex items-center gap-2">
            <base-vite-icon
              name="show"
              classes="stroke-black fill-black stroke-0 w-4 h-4"
            />
            <span
              class="text-black font-bold text-2xl text-left"
              data-cy="anonymous-name"
              >Anonymous Corporate</span
            >
          </div>
          <span
            class="text-grey2 text-base text-left"
            data-cy="anonymous-description"
            >This corporation has decided not to reveal their identity.</span
          >
        </div>
        <base-vite-icon
          name="chevron-right"
          v-if="!isScreenLargeEnough"
          classes="w-5 h-5 stroke-2 stroke-black"
          @click="isMobileOpen = !isMobileOpen"
          role="button"
        >
        </base-vite-icon>
      </div>

      <!-- Corporate Details -->
      <div
        class="flex flex-col gap-4 items-start"
        v-if="
          challengeDetails?.Challenge_Visibility__c == 'Public' && isMobileOpen
        "
        data-cy="corporate-details"
      >
        <span class="text-lg font-bold">Details</span>
        <div class="flex flex-col items-start gap-3 text-base text-grey1">
          <!-- Website -->
          <div class="flex items-center gap-2" v-if="corporateDetails.Website">
            <base-vite-icon
              name="globe"
              classes="fill-black stroke-0 w-4 h-4"
            />
            <a
              class="text-start truncate w-[13rem] underline"
              :href="corporateDetails.Website"
              target="_blank"
              >{{ corporateDetails.Website }}</a
            >
          </div>
          <!-- HQ Country -->
          <div
            class="flex items-center gap-2"
            v-if="corporateDetails.HQ_Country__c"
          >
            <base-vite-icon
              name="location"
              classes="stroke-black stroke-2 fill-none w-4 h-4"
            />
            <span class="text-start truncate w-[11rem]">{{
              corporateDetails.HQ_Country__c
            }}</span>
          </div>
        </div>
      </div>
      <!-- Corporate Sponsorship -->
      <div
        class="flex flex-col gap-4 items-start"
        v-if="
          corporateDetails?.accountSponsorDetails?.active &&
          challengeDetails?.Challenge_Visibility__c == 'Public' &&
          isMobileOpen
        "
        data-cy="corporate-sponsorship"
      >
        <span class="text-lg font-bold">Ecosystem Partnership</span>

        <div class="flex items-center gap-2">
          <base-logo :companyId="sponsorship.sponsorId" classes="w-8 h-8" />
          <p class="max-w-[10rem] ml-4 text-base text-grey1 text-left">
            {{ sponsorship.sponsorName }}
          </p>
        </div>
      </div>
      <!-- Corporate Industries -->
      <div
        class="flex flex-col gap-4 items-start"
        v-if="getCategorization?.length > 0 && isMobileOpen"
        data-cy="corporate-industries"
      >
        <span class="text-lg font-bold">Industries</span>

        <div class="flex items-center gap-2">
          <div
            class="mtb-chip text-sm bg-data6-light text-data6"
            v-for="label in getCategorization.slice(0, 2)"
            :key="label"
          >
            {{ label }}
          </div>
          <span
            v-if="getCategorization.length > 2"
            @mouseenter="toggleOverlay($event)"
            @mouseleave="toggleOverlay($event)"
            class="mtb-chip text-sm bg-data6-light text-data6"
            >+ {{ getCategorization.length - 2 }}
          </span>
          <OverlayPanel ref="overlay" class="z-10" :dismissable="true">
            <!-- Content based on the selected row's data -->
            {{ getCategorization.slice(2).join(", ") }}
          </OverlayPanel>
        </div>
      </div>
      <!-- Corporate Description -->
      <div
        class="flex flex-col gap-4 items-start"
        v-if="
          corporateDetails?.Description &&
          challengeDetails?.Challenge_Visibility__c == 'Public' &&
          isMobileOpen
        "
        data-cy="corporate-description"
      >
        <span class="text-lg font-bold">Description</span>
        <span
          v-html="corporateDetails.Description"
          class="text-base text-start text-grey1 mr-2"
        >
        </span>
      </div>
    </div>
    <!-- RIGHT PART -->
    <div class="mtb-card lg:h-full w-full px-8 py-6" data-cy="right-part">
      <!-- Skeleton if loading -->
      <div
        class="flex flex-col items-start gap-8 h-full overflow-y-scroll"
        v-if="isChallengeDetailsLoading"
      >
        <Skeleton class="w-40 h-8 mb-0"></Skeleton>
        <Skeleton class="w-12 h-6 mb-0"></Skeleton>
        <div class="flex flex-col gap-4 items-start w-full">
          <Skeleton class="w-20 h-5 mb-0"></Skeleton>
          <Skeleton class="w-full h-5 mb-0"></Skeleton>
        </div>
      </div>
      <!-- Real content -->
      <div v-else class="h-full overflow-y-hidden flex flex-col gap-y-6">
        <div
          class="flex flex-row flex-wrap gap-y-2 justify-between items-center pb-6 border-grey3 border-b border-b-1"
        >
          <span
            class="text-[20px] capitalize font-bold text-black text-left"
            data-cy="challenge-name"
            >{{ challengeDetails?.Name.toLowerCase() }}</span
          >
          <base-button
            v-if="
              userAuthStore?.state?.accountInfo?.type == 'startup' &&
              !isAppliedChallenge
            "
            severity="primary"
            :text="false"
            label="Apply"
            icon="add"
            :iconClass="`w-4 h-4 stroke-2 stroke-white
            `"
            @click="applyToChallenge"
            :stopClick="false"
          />
          <base-button
            v-if="
              userAuthStore?.state?.accountInfo?.type == 'startup' &&
              isAppliedChallenge
            "
            severity="primary"
            :text="false"
            label="
              Applied
            "
            :iconClass="`w-4 h-4 stroke-2 stroke-white
            `"
            :stopClick="false"
          />
          <router-link
            v-if="isMyChallenge"
            :to="{
              name: 'challenge-details-view',
              params: { corporateId: corporateId, id: challengeId }
            }"
            data-cy="manage-challenge-btn"
          >
            <base-button
              severity="primary"
              :text="false"
              label="Manage Challenge"
              icon="edit"
              :iconClass="`w-4 h-4 stroke-2 stroke-white
            `"
              :stopClick="false"
            />
          </router-link>
        </div>
        <div
          class="h-full lg:overflow-y-scroll overflow-y-hidden flex flex-col gap-y-6"
          data-cy="challenge-section-container"
        >
          <div
            class="flex flex-col gap-y-4"
            :class="
              section.index != 2 ? 'border-grey3 border-b border-b-1 pb-6' : ''
            "
            v-for="section in sectionConfig"
            :key="section.index"
            :data-cy="`challenge-section-${section.index}`"
          >
            <!-- Section title -->
            <span class="text-xl text-black font-bold text-left">{{
              section.label
            }}</span>
            <!-- Section fields -->
            <div class="grid grid-cols-2 gap-4">
              <!-- Status section -->
              <div
                v-if="section.index == 0"
                class="flex flex-col items-start gap-1.5"
              >
                <span class="text-lg text-black font-bold">Status</span>
                <div
                  class="mtb-chip font-bold text-sm"
                  :class="
                    statusMapping[challengeDetails?.Challenge_State__c]
                      ?.parentClasses
                  "
                >
                  <span class="text-base font-normal text-left">{{
                    challengeDetails?.Challenge_State__c || "--"
                  }}</span>
                </div>
              </div>
              <div
                class="flex flex-col items-start gap-1.5"
                v-for="field in allNeededFields(challengeDetails).filter(
                  (ele) =>
                    section.step?.includes(ele.step) &&
                    ele.id != 'Name' &&
                    ele.visibleToStartups
                )"
                :class="
                  field.id == 'Challenge_Description__c' ? 'col-span-2' : ''
                "
                :key="field.id"
              >
                <span class="text-lg font-bold text-left text-black"
                  >{{ field.label }}
                </span>
                <div
                  v-if="field.id == 'Challenge_Objective__c'"
                  class="mtb-chip font-bold text-sm"
                  :class="
                    objectiveMapping[challengeDetails?.Challenge_Objective__c]
                      .parentClasses
                  "
                >
                  <span class="text-base font-normal text-left">{{
                    challengeDetails["Challenge_Objective__c"]
                  }}</span>
                </div>
                <span
                  v-else
                  class="text-base text-grey1 font-normal text-left"
                  >{{ formatValue(field, challengeDetails?.[field.id]) }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  ref,
  onMounted,
  onUnmounted,
  watchEffect,
  defineAsyncComponent
} from "vue";
import {
  statusMapping,
  objectiveMapping
} from "@global/configs/challenge-mapping.js";
import { getSingleChallengeInfo } from "@/global/services/challenges-service.js";
import { getAccountsListDetails } from "@/global/services/accounts-service.js";
import { challengeFieldModalConfig } from "@corporates/configs/challenge-field-modal-config.js";
import { useChallengeDetails } from "@/global/hooks/use-challenge-details.js";
import { useRoute } from "vue-router";
import { useUserAuthStore } from "@/global/stores/user-auth-store";
import { useStartupApplicationModal } from "@startups/hooks/use-startup-application-modal.js";
import { useOpportunitiesStore } from "@/global/stores/opportunities-store";
import { default as envConstants } from "@share/envConstant.js";
import { useAccountsStore } from "@global/stores/accounts-store.js";
import { decryptValue } from "../helpers/hash";

const ErrorPages = defineAsyncComponent(
  () => import("@/global/layouts/mtb-errors-pages.vue")
);

const { showApplicationModal } = useStartupApplicationModal();
const { formatValue, allNeededFields } = useChallengeDetails();
const route = useRoute();

const userAuthStore = useUserAuthStore();
const accountsStore = useAccountsStore();
const corporateDetails = ref({});
const challengeDetails = ref({});
const isChallengeDetailsLoading = ref(true);
const isCorporateDetailsLoading = ref(true);
const errorCode = ref(null);
const challengeId = ref(null);
const corporateId = ref(null);

const updateChallengeId = async (id) => {
  challengeId.value = await decryptValue(id);
};

const updateCorporateId = async (id) => {
  corporateId.value = await decryptValue(id);
};

watchEffect(() => {
  if (route.params.id) {
    updateChallengeId(route.params.id);
  }
});

watchEffect(() => {
  if (route.params.corporateId) {
    updateCorporateId(route.params.corporateId);
  }
});
const sponsorship = computed(() => {
  return [
    ...(accountsStore.state.sponsorshipsList?.startups || []),
    ...(accountsStore.state.sponsorshipsList?.corporate || [])
  ].find(
    (ele) =>
      ele.companyId === corporateId.value &&
      !envConstants.INSTITUTION_TEST_DEMO_ACCOUNTS.includes(ele?.sponsorId)
  );
});
const getCategorization = computed(() => {
  const sectors =
    corporateDetails.value?.Industry_Sector__c &&
    corporateDetails.value?.Industry_Sector__c
      ? corporateDetails.value?.Industry_Sector__c.replaceAll("\n", ", ")
          .replaceAll(";", ", ")
          .split(", ")
          .map((sc) => sc.trim())
      : [];
  const keywords = corporateDetails.value?.Keywords__c
    ? corporateDetails.value?.Keywords__c.replaceAll("\n", ", ")
        .replaceAll(";", ", ")
        .split(", ")
        .map((kw) => kw.trim())
    : [];
  const technologies = corporateDetails.value?.Technologies__c
    ? corporateDetails.value?.Technologies__c.split(";").map((tech) =>
        tech.trim()
      )
    : [];
  const verticals = corporateDetails.value?.Industry_Verticals__c
    ? corporateDetails.value?.Industry_Verticals__c.split(";").map((vert) =>
        vert.trim()
      )
    : [];

  // Combine all arrays and create a Set to remove duplicates
  const combined = [
    ...new Set([...sectors, ...verticals, ...technologies, ...keywords])
  ];

  return combined;
});

const overlay = ref();
const toggleOverlay = (event) => {
  overlay.value?.toggle(event);
};

const sectionConfig = [
  {
    label: "Basic Info",
    index: 0,
    step: [0, 1]
  },
  {
    label: "Needs & Details",
    index: 1,
    step: [2]
  },
  {
    label: "Additional Information",
    index: 2,
    step: [3]
  }
];

// Logic for the mobile view dropdown
const isScreenLargeEnough = ref(window.innerWidth >= 640);
const isMobileOpen = ref(isScreenLargeEnough.value ? true : false);

function checkScreenSize() {
  if (window.innerWidth < 640) {
    isScreenLargeEnough.value = false;
    isMobileOpen.value = false;
  } else {
    isScreenLargeEnough.value = true;
    isMobileOpen.value = true;
  }
}

const isMyChallenge = computed(() => {
  //define here call to action
  //check if account id and user info are equal
  return corporateId.value === userAuthStore.state?.accountInfo?.id;
});
watchEffect(async () => {
  if (!challengeId.value || !corporateId.value) {
    return;
  }
  try {
    const challengeData = await getSingleChallengeInfo({
      challengeId: challengeId.value,
      corporateId: corporateId.value
    });

    challengeDetails.value = challengeData;
    corporateDetails.value = challengeData["Challenge_Corporate_Sponsor__r"];
    errorCode.value = null;
  } catch (error) {
    if (error.message.indexOf("Access denied") !== -1) {
      errorCode.value = 403;
    } else if (error.message.indexOf("Challenge not found") !== -1) {
      errorCode.value = 404;
    } else {
      errorCode.value = 500;
    }
  } finally {
    isChallengeDetailsLoading.value = false;
    isCorporateDetailsLoading.value = false;
  }
});
onMounted(async () => {
  window.addEventListener("resize", checkScreenSize);
  if (userAuthStore?.state?.accountInfo?.type == "startup") {
    oppostunityStore.actions.setStartupOpportunities([
      userAuthStore.state.accountInfo?.id
    ]);
  }
});
onUnmounted(() => {
  window.removeEventListener("resize", checkScreenSize);
});
/////APPLY to challenge if startup
//TODO: change the logic with the new BE
const oppostunityStore = useOpportunitiesStore();
const isAppliedChallenge = computed(() => {
  if (userAuthStore?.state?.accountInfo?.type !== "startup") {
    return false;
  }
  const isOpportunity = oppostunityStore.state.startupOpportunities.find(
    (el) => {
      return (
        el.startupId === userAuthStore.state?.accountInfo?.id &&
        el.challengeId == challengeId.value
      );
    }
  );
  return isOpportunity;
});
const applyToChallenge = async () => {
  showApplicationModal({
    currentApplication: null,
    currentChallenge: {
      id: challengeId.value,
      name: challengeDetails.value.Name,
      corporateName: corporateDetails.value.Name || corporateId.value,
      corporateId: corporateId.value
    }
  });
};
</script>

<style></style>
