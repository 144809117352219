<template>
  <!-- <iframe
    src="http://localhost:8080/startup-profile/0014U00002f7JlmQAE?_share=true&_embed=true"
    frameborder="0"
    allowfullscreen
    style="width: 100%; height: 100vh"
  ></iframe> -->
  <Toast>
    <template #message="slotProps">
      <div class="flex flex-col items-start flex-auto">
        <div class="font-medium text-lg" v-if="slotProps.message.summary">
          {{ slotProps.message.summary }}
        </div>
        <div class="text-md" v-if="slotProps.message.detail">
          {{ slotProps.message.detail }}
        </div>
        <div
          v-if="slotProps.message.reportIssue"
          class="text-sm font-semibold text-primary mt-3 cursor-pointer flex items-center"
          @click="bus.emit('show-sentry-feedback')"
        >
          <base-vite-icon
            name="bug"
            classes="w-4 fill-white stroke-primary mr-1"
          />
          Report an issue
        </div>
      </div>
    </template>
  </Toast>
  <ConfirmDialog></ConfirmDialog>
  <DynamicDialog />
  <Message
    :sticky="true"
    :closable="false"
    v-if="auth.currentUser && !isScreenLargeEnough && !route.query._embed"
    :pt="{
      wrapper: 'flex items-center p-5 h-full',
      text: 'text-xl font-normal h-full',
      icon: 'hidden',
      closebutton: 'w-6 absolute top-[20px] right-[10px]'
    }"
    class="!my-0 !mt-0 absolute z-[999] w-full h-full !bg-white rounded-none"
  >
    <div class="flex flex-col items-center gap-6 h-full justify-center">
      <base-vite-icon
        name="logo-login"
        subFolderName="images"
        classes="w-20 h-20"
      />
      <span
        >MTB Ecosystem won't work at its best on mobile phones or small screens.
        Please, use a bigger screen to be able to have a better
        experience.</span
      >
    </div>
  </Message>

  <base-sidebar-challenge
    v-if="
      userAuthStore.state.accountInfo?.type !== 'corporate' ||
      userAuthStore.state.userInfo?.Claims?.mtb
    "
  />
  <base-sidebar-application />
  <base-sidebar-account-details />
  <base-sidebar-startups-list
    v-if="userAuthStore.state.accountInfo?.type === 'corporate'"
  />
  <div
    :class="{
      'bg-primary': navbarIsVisible
    }"
  >
    <mtb-navigation v-if="navbarIsVisible" />
    <div
      class="h-screen"
      :class="{
        'lg:ml-20 overflow-hidden lg:rounded-s-3xl': navbarIsVisible
      }"
    >
      <mtb-header
        v-if="
          (navbarIsVisible && !route.meta.hideHeader) ||
          ((route.name == 'signin' || route.name == 'request-account') &&
            !isScreenLargeEnough)
        "
      />

      <main
        class="bg-grey4 sm:px-6 main-container overflow-y-auto px-0"
        :class="mainHeightClass"
      >
        <div class="h-full max-w-[1500px] m-auto">
          <!-- //TODO: add the dynamic transition effect in the meta or Finf a common animation, note animation want a root node -->
          <router-view v-slot="{ Component }">
            <transition name="fade">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import { mixpanelPeople } from "@/plugins/mixpanel.js";
import { useUserAuthStore } from "@/global/stores/user-auth-store.js";

import { auth } from "@plugins/firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  onBeforeMount
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { bus } from "./main.js";
import { GLOBAL_SIGNIN } from "@global/router.js";
import { useAccountsStore } from "./global/stores/accounts-store.js";
import { useChallengesStore } from "./global/stores/challenges-store.js";
import { differenceInDays } from "date-fns";
import { useFetch } from "@/global/hooks/use-fetch.js";
import { getActivePinia } from "pinia";
import { encryptSessionStorage, encryptLocalStorage } from "@/plugins/pinia.js";
import localforage from "localforage";
import { default as constants } from "@share/envConstant.js";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";

const router = useRouter();
const route = useRoute();
const userAuthStore = useUserAuthStore();
const accountsStore = useAccountsStore();
const challengeStore = useChallengesStore();
const toast = useToast();
//////////
const sessionCheckInterval = ref(null);
const refreshInterval = ref(null);
const refreshUnauthInterval = ref(null);
const storesActions = useFetch();
const navbarIsVisible = computed(() => {
  return !!route.meta.navbar && route.query._embed !== "true";
});

const mainHeightClass = computed(() => {
  if (auth.currentUser) {
    if (
      (navbarIsVisible.value && !route.meta.hideHeader) ||
      !isScreenLargeEnough.value
    ) {
      return "h-[calc(100%-80px)]";
    } else {
      return "h-full";
    }
  } else {
    if (navbarIsVisible.value && !route.meta.hideHeader) {
      return "sm:h-[calc(100%-106px)] h-[calc(100%-151px)]";
    } else if (route.name == "signin" || route.name == "request-account") {
      return "h-[calc(100%-80px)] sm:h-full";
    } else {
      return "h-full";
    }
  }
});

onMounted(() => {
  bus.on("account-is-changed", async () => {
    //event on teleport
    const profile = userAuthStore.state.accountInfo; //==> assign new accountInfo
    await Promise.all([
      resetPolling(), //==> reset the polling in useRequest (avoid double polling or polling on wrong actions)
      resetPiniaStores() //==> clean up stores (useful in dev mode for avoid dummy mistake)])
    ]);
    //Update sponsorship for test-demo, different based on account id
    storesActions.setSponsorshipsList.runAsync();
    onAccountChange(profile);
  });
  window.addEventListener("resize", checkScreenSize);
  //this function is called every change of route and refresh, if the user is already present return
  onAuthStateChanged(auth, async (currentUser) => {
    checkScreenSize();
    if (!currentUser) {
      if (!isScreenLargeEnough.value && !route.query._embed) {
        toast.add({
          severity: "info",
          summary: `MTB Ecosystem won't work at its best on mobile phones or small screens.`,
          detail: `Please, use a bigger screen to be able to have a better
        experience.`,
          life: 5000
        });
      }
      //this piece of code doesn't work all the time
      if (route.meta.auth) {
        //autoredirect if the page is private
        router.push({ name: GLOBAL_SIGNIN });
      }
      const unauthData = encryptSessionStorage.getItem("unauth-init");
      //reset all the store, no auth available
      encryptSessionStorage.clear();
      //TODO: comment if we want to keep the info about the prev user in the storage
      encryptLocalStorage.setItem("user-auth", null);
      encryptSessionStorage.setItem("unauth-init", unauthData);
      userAuthStore.actions.reset();
      localforage.clear();
      await Promise.all([
        resetPolling(), //==> reset the polling in useRequest (avoid double polling or polling on wrong actions)
        resetPiniaStores() //==> clean up stores (useful in dev mode for avoid dummy mistake)])
      ]);

      loadUnauthData();
    } else {
      console.log("💪 userInfo: ", userAuthStore.state.userInfo ? "OK" : "KO");
      console.log(
        "💪 accountInfo: ",
        userAuthStore.state.accountInfo ? "OK" : "KO"
      );
      console.log(
        "💪 session-init: ",
        encryptSessionStorage.getItem("session-init") ? "OK" : "KO"
      );
      //run a watcher for auto logout after 15 days
      sessionWatcher();
      openModalByQuery();
      if (
        userAuthStore.state.userInfo &&
        userAuthStore.state.accountInfo &&
        encryptSessionStorage.getItem("session-init")
      ) {
        //if already exist a session means that we lost all the previous polling on the store
        //let's start a timer for re-trigger the lost polling behaviour
        startPolling();
        startUnauthPolling();
        return;
      }

      !userAuthStore.state.accountInfo &&
        (await userAuthStore.actions.setAccountInfo());
      await loadAccountData(userAuthStore.state.accountInfo);
      mixpanelPeople({ userData: userAuthStore.state.userInfo, once: true });
    }
    userAuthStore.actions.setLogin(currentUser);
  });
});

const onAccountChange = (profile) => {
  if (profile) {
    localforage.clear();
    //reload initial data
    resetPersonalStore();
    loadAccountData(profile);
  } else {
    //something went wrong, let's start from clean situation
    auth.signOut();
    encryptLocalStorage.clear();
    encryptSessionStorage.clear();
    localforage.clear();
    userAuthStore.actions.reset();
    router.push({
      name: GLOBAL_SIGNIN
    });
  }
};
function resetPersonalStore() {
  const allItems = { ...encryptSessionStorage };
  for (const key in allItems.storage) {
    if (key.startsWith("query_")) {
      encryptSessionStorage.removeItem(key);
    }
  }
  //TODO add reset of personal store
}
/**--POLLING management--**/
const isPollingActive = reactive({
  global: true,
  startup: !!userAuthStore.state?.accountInfo?.type === "startup",
  corporate: !!userAuthStore.state?.accountInfo?.type === "corporate",
  institution: !!userAuthStore.state?.accountInfo?.type === "institution"
});
/**--------------**/
const loadUnauthData = async (force) => {
  isSponsorshipNeedReload() || force
    ? await storesActions.setSponsorshipsList.runAsync()
    : "OK";

  encryptSessionStorage.setItem("unauth-init", new Date());
};

const loadAccountData = async (profile) => {
  if (process.env.NODE_ENV === "development") {
    console.log("profile", profile);
  }
  isPollingActive.global = true;

  await loadUnauthData(userAuthStore.getters.isDemoAccount);
  if (process.env.NODE_ENV === "development") {
    console.log("profile.type", profile.type);
  }

  switch (profile.type) {
    case "startup":
      isPollingActive.startup = true;
      if (process.env.NODE_ENV === "development") {
        console.log("i am startup");
      }

      await Promise.allSettled([
        storesActions.setEcosystemPublishedChallengesList.runAsync(),
        storesActions.setAllMatchingCalendars.runAsync(),
        storesActions.setStartupSuggestedChallenges.runAsync(profile.id),
        storesActions.setStartupDislikeChallenges.runAsync(profile.id),
        storesActions.setStartupLikeChallenges.runAsync(profile.id),
        storesActions.setStartupOpportunities.runAsync([profile.id])
      ]);
      break;
    case "corporate":
      isPollingActive.corporate = true;
      await Promise.allSettled([
        storesActions.setAccountChallengesList.runAsync(profile.id),
        storesActions.setFavouriteLists.runAsync(profile.id),
        storesActions.setCorporateMeetings.runAsync({
          corporateId: profile.id
        })
      ]);

      break;
    case "institution":
      isPollingActive.institution = true;
      //if you want to pass a param EX run('Benny', 18);
      await storesActions.setEcosystemPublishedChallengesList.runAsync();
      await Promise.allSettled([
        storesActions.setAdditionalInfoStartups.runAsync(),
        storesActions.setAdditionalInfoCorporates.runAsync(),
        storesActions.setInstitutionProfileExposure.runAsync(),
        storesActions.setSponsoredCorporateExposure.runAsync(),
        storesActions.setSponsoredStartupsExposure.runAsync(),
        storesActions.setChallengeSuggestionEmail.runAsync()
      ]);
      break;
  }
  encryptSessionStorage.setItem("session-init", true); //activate current session
};
function isNeedGlobalHydratation() {
  const data = encryptSessionStorage.getItem("unauth-init");
  if (!data || data === "undefined" || data === "null") {
    return true;
  }
  return !JSON.parse(data) || !getDifferenceInDays(JSON.parse(data)) >= 2;
}

function isSponsorshipNeedReload() {
  return (
    isNeedGlobalHydratation() ||
    !accountsStore.state.sponsorshipsList.corporate ||
    !Array.isArray(accountsStore.state.sponsorshipsList.corporate) ||
    !accountsStore.state.sponsorshipsList.startups ||
    !Array.isArray(accountsStore.state.sponsorshipsList.corporate)
  );
}

const startPolling = () => {
  //when the user refresh the page start the polling after 3 hour time
  refreshInterval.value = setTimeout(
    () => {
      loadAccountData(userAuthStore.state.accountInfo);
    },
    3 * 60 * 60 * 1000
  );
};
const startUnauthPolling = () => {
  //when the user refresh the page start the polling after 3 hour time
  refreshUnauthInterval.value = setTimeout(
    () => {
      loadUnauthData();
    },
    3 * 60 * 60 * 1000
  );
};
const resetPolling = () => {
  return new Promise((resolve) => {
    // encryptSessionStorage.setItem("unauth-init", null);
    isPollingActive.global = false;
    isPollingActive.startup = false;
    isPollingActive.corporate = false;
    isPollingActive.institution = false;
    storesActions.restore();
    if (process.env.NODE_ENV === "development") {
      console.log("🗑️ Resetting polling complete");
    }
    resolve();
  });
};
const resetPiniaStores = () => {
  return new Promise((resolve) => {
    const excludeStore = ["user-auth", "accounts", "featured-list"];
    const stores = getActivePinia()?.state.value;
    Object.keys(stores)?.map((key) => {
      if (!excludeStore.includes(key)) {
        stores[key].actions.reset();
      }
    });
    if (process.env.NODE_ENV === "development") {
      console.log("🗑️ Resetting store complete");
    }
    resolve();
  });
};
const sessionWatcher = () => {
  //check every 12 hours if the session is expired
  sessionCheckInterval.value = setInterval(
    () => {
      isValidSession();
    },
    12 * 60 * 60 * 1000
  ); //every 12 hours
};
function getDifferenceInDays(initDate) {
  const lastLoginDate = new Date(initDate);
  const today = new Date();
  return differenceInDays(today, lastLoginDate);
}
const isValidSession = () => {
  const lastLogin = userAuthStore.state.init;
  if (!lastLogin) {
    return;
  }
  const difference = getDifferenceInDays(lastLogin);
  //ADD A TIMEOUT HERE remeber to clear the timer on unmount
  if (difference > 15) {
    auth.signOut();
    encryptSessionStorage.removeItem("session-init");
    //DEBT: Keep monitor this. For now when we logout users we want to redirect them to the signin page
    router.push({
      name: GLOBAL_SIGNIN
    });
  }
};
////////modal
import { useChallengeModal } from "@global/hooks/use-challenge-modal.js";

const { showChallengeModal } = useChallengeModal();
const openModalByQuery = () => {
  setTimeout(() => {
    const query = route.query;
    if (query?.challenge_m === "new") {
      showChallengeModal();
    } else if (query?.challenge_m?.includes("edit_")) {
      const challengeId = query?.challenge_m?.split("edit_")[1];
      const currentChallenge = challengeStore.state.accountChallenges.find(
        ({ Id }) => Id == challengeId
      );
      if (currentChallenge) {
        showChallengeModal(currentChallenge);
      } else {
        const query = { ...route.query };
        delete query["challenge_m"];
        router.replace({ ...route, query });
      }
    }
  }, 200);

  //
};

const isScreenLargeEnough = ref(window.innerWidth >= 640);

function checkScreenSize() {
  if (window.innerWidth < 640) {
    isScreenLargeEnough.value = false;
  } else {
    isScreenLargeEnough.value = true;
  }
}

onUnmounted(() => {
  resetPolling();

  clearInterval(sessionCheckInterval.value);
  sessionCheckInterval.value = null;
  clearTimeout(refreshInterval.value);
  refreshInterval.value = null;
  bus.off("account-is-changed");
  window.removeEventListener("resize", checkScreenSize);
});
</script>

<style lang="scss">
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar:hover {
  width: 10px;
  height: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #ffffff00;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 500px;
}
::-webkit-scrollbar-corner {
  background: #ffffff00;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(126, 126, 126);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
